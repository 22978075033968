$("input[type=datetime]").datepicker({
    changeMonth: true,
    changeYear: true,
    yearRange: "2020:2099"
});

$(function () {
    jQuery.widget("custom.combobox", {
        _create: function () {
            this.wrapper = $("<span>")
                .addClass("custom-combobox")
                .insertAfter(this.element);

            this.element.hide();
            this._createAutocomplete();
            this._createShowAllButton();
        },

        _createAutocomplete: function () {
            var selected = this.element.children(":selected"),
                value = selected.val() ? selected.text() : "";

            this.input = $("<input>")
                .appendTo(this.wrapper)
                .val(value)
                .attr("title", "")
                .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
                .autocomplete({
                    delay: 0,
                    max: 10,
                    minLength: 2,
                    source: $.proxy(this, "_source")
                })
                .tooltip({
                    classes: {
                        "ui-tooltip": "ui-state-highlight"
                    }
                });

            this._on(this.input, {
                autocompleteselect: function (event, ui) {
                    ui.item.option.selected = true;
                    this._trigger("select", event, {
                        item: ui.item.option
                    });
                },

                autocompletechange: "_removeIfInvalid"
            });
        },

        _createShowAllButton: function () {
            var input = this.input,
                wasOpen = false;

            $("<a>")
                .attr("tabIndex", -1)
                //.attr( "title", "Show All Items" )
                .tooltip()
                .appendTo(this.wrapper)
                .button({
                    icons: {
                        primary: "ui-icon-triangle-1-s"
                    },
                    text: false
                })
                .removeClass("ui-corner-all")
                .addClass("custom-combobox-toggle ui-corner-right")
                .on("mousedown", function () {
                    wasOpen = input.autocomplete("widget").is(":visible");
                })
                .on("click", function () {
                    input.trigger("focus");

                    // Close if already visible
                    if (wasOpen) {
                        return;
                    }

                    // Pass empty string as value to search for, displaying all results
                    input.autocomplete("search", "");
                });
        },

        _source: function (request, response) {
            var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
            response(this.element.children("option").map(function () {
                var text = $(this).text();
                if (this.value && (!request.term || matcher.test(text)))
                    return {
                        label: text,
                        value: text,
                        option: this
                    };
            }));
        },

        _removeIfInvalid: function (event, ui) {

            // Selected an item, nothing to do
            if (ui.item) {
                return;
            }

            // Search for a match (case-insensitive)
            var value = this.input.val(),
                valueLowerCase = value.toLowerCase(),
                valid = false;
            this.element.children("option").each(function () {
                if ($(this).text().toLowerCase() === valueLowerCase) {
                    this.selected = valid = true;
                    return false;
                }
            });

            // Found a match, nothing to do
            if (valid) {
                return;
            }

            // Remove invalid value
            this.input
                .val("")
                .attr("title", value + " didn't match any item")
                .tooltip("open");
            this.element.val("");
            this._delay(function () {
                this.input.tooltip("close").attr("title", "");
            }, 2500);
            this.input.autocomplete("instance").term = "";
        },

        _destroy: function () {
            this.wrapper.remove();
            this.element.show();
        }
    });

    $(".autocomplete").autocomplete({
        delay: 0,
        minLength: 1,
        appendTo: "#autocomplete-container",
        select: function (event, ui) {
            ui.item.option.source.find('option').prop('selected', false);
            ui.item.option.source.find('option[value=' + ui.item.option.value + ']').prop('selected', true);
        },
        source: function (request, response) {
            sourceS = $("#" + this.element.data("source"));
            var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
            response(sourceS.children().map(function () {
                var text = $(this).text();
                if ((!request.term || matcher.test(text)))
                    return {
                        label: text,
                        value: text,
                        option: {"source": sourceS, "value": $(this).val()}
                    };
            }));
        }
    })
});

(function ($) {

    $.fn.item = function (options) {

        var settings = $.extend({
            output: null,
            input: null,
            onSelect: function () {
            },
            onStart: function () {
            },
            buttonStyle: "btn-secondary",
        }, options);

        return this.each(function () {

            let name = $(this).attr('name');
            let mainclass = 'item-' + name;
            let modalId = 'item' + name;
            settings.output = $(this).data('input');
            settings.input = $(this).attr('name');
            let currentValue = $(this).data('value');

            $(this).after('<div class="item-container item-container-' + name + '"></div>');
            $(this).wrap('<div class="input-group mb-3 ' + mainclass + '"></div>');

            $(this).parent().prepend('<div class="input-group-prepend spinner-' + name + '" style="display: none;"><div class="fa-2x input-group-text"><i class="fas fa-spinner fa-spin"></i></div></div>');
            $(this).parent().append('<input type="hidden" name="' + settings.output + '" id="' + settings.output + '" value="' + currentValue + '" />');

            $('body').append('<style>' +
                '.item-container {display: block;position:relative;}' +
                '.ui-autocomplete {position: absolute;}' +
                '</style>');

            $(this).autocomplete({
                appendTo: ".item-container-" + name,
                source: "/ajax/item",
                minLength: 0,
                select: function (event, ui) {
                    event.preventDefault();
                    $('#' + settings.output).val(ui.item.value);
                    $('#' + settings.input).val(ui.item.label);
                },
                focus: function (event, ui) {
                    event.preventDefault();
                },
                search: function (event, ui) {
                    $('.spinner-' + name).show();
                },
                response: function (event, ui) {
                    $('.spinner-' + name).hide();
                }
            }).focus(function () {
                //$(this).data("uiAutocomplete").search($(this).val());
            });

        });
    };

    $.fn.subdivision = function (options) {

        let settings = $.extend({
            output: null,
            input: null,
            onSelect: function () {
            },
            onStart: function () {
            },
            buttonStyle: "btn-secondary",
        }, options);

        return this.each(function () {

            let name = $(this).attr('name');
            let mainclass = 'subdivision-' + name;
            let modalId = 'subdivision' + name;
            settings.output = $(this).data('input');
            settings.input = $(this).attr('name');
            let currentValue = $(this).data('value');

            $(this).after('<div class="subdivision-container subdivision-container-' + name + '"></div>');
            $(this).wrap('<div class="input-group mb-3 ' + mainclass + '"></div>');

            $(this).parent().prepend('<div class="input-group-prepend spinner-' + name + '" style="display: none;"><div class="fa-2x input-group-text"><i class="fas fa-spinner fa-spin"></i></div></div>');
            $(this).parent().append('<input type="hidden" name="' + settings.output + '" id="' + settings.output + '" value="' + currentValue + '" />');

            $('body').append('<style>' +
                '.subdivision-container {display: block;position:relative;}' +
                '.ui-autocomplete {position: absolute;}' +
                '</style>');

            $(this).autocomplete({
                appendTo: ".subdivision-container-" + name,
                source: "/ajax/subdivision",
                minLength: 0,
                select: function (event, ui) {
                    event.preventDefault();
                    $('#' + settings.output).val(ui.item.value);
                    $('#' + settings.input).val(ui.item.label);
                },
                focus: function (event, ui) {
                    event.preventDefault();
                    //$('#'+settings.input).val(ui.item.label);
                },
                search: function (event, ui) {
                    $('.spinner-' + name).show();
                },
                response: function (event, ui) {
                    $('.spinner-' + name).hide();
                }
            }).focus(function () {
                // The following works only once.
                // $(this).trigger('keydown.autocomplete');
                // As suggested by digitalPBK, works multiple times
                // $(this).data("autocomplete").search($(this).val());
                // As noted by Jonny in his answer, with newer versions use uiAutocomplete
                //$(this).data("uiAutocomplete").search($(this).val());
            });

        });
    };

    $.fn.client = function (options) {

        var settings = $.extend({
            output: null,
            input: null,
            name: null,
            onSelect: function () {
            },
            onStart: function () {
            },
            buttonStyle: "btn-secondary",
        }, options);

        return this.each(function () {

            let name = $(this).attr('name');
            let mainclass = 'client-' + name;
            let modalId = 'client' + name;
            settings.output = $(this).data('input');
            settings.input = $(this).attr('name');
            let currentValue = $(this).data('value');
            settings.name = $(this).data('name');

            if (settings.name === null || settings.name === undefined) {
                settings.name = settings.output;
            }

            $(this).after('<div class="client-container client-container-' + name + '"></div>');
            $(this).wrap('<div class="input-group mb-3 ' + mainclass + '"></div>');

            $(document).on('click', '.client-sync-' + name + ' button', function () {
                let element = $(this).find('i');
                $.post("/ajax/action/" + $('#subdivision_id').val(), {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    action: 'QueryPartnerV2',
                    ClientRegId: $('#' + settings.input).val()
                }, function (data) {
                    element.removeClass('fa-sync');
                    $('#' + name + 'Help').html(data.data);
                    if (data.success) {
                        element.addClass('fa-clock');
                    } else {
                        element.addClass('fa-exclamation-circle');
                    }
                }, "json");
            });
            $(this).parent().prepend('<div class="input-group-prepend spinner-' + name + '" style="display: none;"><div class="fa-2x input-group-text"><i class="fas fa-spinner fa-spin"></i></div></div>');
            $(this).parent().append('<div class="input-group-append client-sync-' + name + '" style="display: none;"><button class="client-action btn ' + settings.buttonStyle + '" type="button"><i class="fas fa-sync"></i></button></div>');
            $(this).parent().append('<input type="hidden" name="' + settings.name + '" id="' + settings.output + '" value="' + currentValue + '" />');

            $('body').append('<style>' +
                '.client-container {display: block;position:relative;}' +
                '.ui-autocomplete {position: absolute;}' +
                '</style>');

            $(this).autocomplete({
                appendTo: ".client-container-" + name,
                source: "/ajax/client",
                minLength: 3,
                select: function (event, ui) {
                    event.preventDefault();
                    $('#' + settings.output).val(ui.item.value);
                    $('#' + settings.input).val(ui.item.label);
                },
                focus: function (event, ui) {
                    event.preventDefault();
                    //$('#'+settings.input).val(ui.item.label);
                },
                search: function (event, ui) {
                    $('.spinner-' + name).show();
                    $('#' + name + 'Help').html("");
                    $('.client-sync-' + name).show();
                },
                response: function (event, ui) {
                    let value = $('#' + settings.input).val();
                    if (ui.content.length == 0 && value.length == 12) {
                        $('.client-sync-' + name).show();
                        $('#' + name + 'Help').html('Не найдена организация, запросите данные в ЕГАИС');
                    }
                    $('.spinner-' + name).hide();
                }
            });

        });
    };


    $.fn.stock = function (options) {

        var settings = $.extend({
            allowForms: true,
            output: null,
            input: null,
            data: {
                product: 0, productName: "",
                form1: "test-a",
                form2: "test-b",
                qty: 0,
            },
            onSelect: function () {
            },
            onStart: function () {
            },
            stockData: {},
            buttonStyle: "btn-secondary",
            type: "product", // product | form
        }, options);

        return this.each(function () {

            let name = $(this).attr('name');
            let mainClass = 'stock-' + name;
            let modalId = 'stock' + name;
            settings.output = $(this).data('input');
            settings.input = $(this).attr('name');
            let currentValue = $(this).data('value');

            $(this).after('<div class="stock-container stock-container-' + name + '"></div>');
            $(this).wrap('<div class="input-group mb-3 ' + mainClass + '"></div>');

            $(document).on('click', '.' + mainClass + ' .table button', function () {
                settings.data.form1 = $(this).data('form1');
                settings.data.form2 = $(this).data('form2');
                settings.data.qty = $(this).data('qty');
                settings.onSelect();
            });
            $(this).parent().prepend('<div class="input-group-prepend spinner-' + name + '" style="display: none;"><div class="fa-2x input-group-text"><i class="fas fa-spinner fa-spin"></i></div></div>');
            $(this).parent().append('<input type="hidden" name="' + settings.output + '" id="' + settings.output + '" value="' + currentValue + '" />');

            $('body').append('<style>' +
                '.stock-container {display: block;position:relative;}' +
                '.ui-autocomplete {position: absolute;}' +
                '</style>');

            if (settings.allowForms) {

                $(this).parent().append('<div class="input-group-append"><button class="btn ' + settings.buttonStyle + '" type="button" data-toggle="modal" data-target="#' + modalId + '"><i class="fas fa-ellipsis-h"></i></button></div>');
                $('body').append(
                    '<div class="modal fade ' + mainClass + '" id="' + modalId + '" tabindex="-1" role="dialog" aria-labelledby="xmlPackTitle" aria-hidden="true">' +
                    '<div class="modal-dialog modal-dialog-scrollable" role="document">' +
                    '<div class="modal-content">' +
                    '<div class="modal-header">' +
                    '<h5 class="modal-title" id="xmlPackTitle">Список выбора</h5>' +
                    '<button type="button" class="close" data-dismiss="modal" aria-label="Закрыть">' +
                    '<span aria-hidden="true">&times;</span>' +
                    '</button>' +
                    '</div>' +
                    '<div class="modal-body">' +
                    '<div class="input-group mb-3">' +
                    '<div class="input-group-prepend spinner-' + mainClass + '" style="display: none;"><div class="fa-2x input-group-text"><i class="fas fa-spinner fa-spin"></i></div></div>' +
                    '<input type="text" class="form-control stock-autocomplete" placeholder="Начните вводит код или название" />' +
                    // '<input type="button" class="pickValue" value="Pick" data-dismiss="modal" />' +
                    '</div>' +
                    '<div class="stock-container"></div><div class="stock-list"><table class="table"><thead><tr><td>Справка 2</td><td>Количество</td><td></td></tr></thead><tbody></tbody></table></div>' +
                    '</div>' +
                    '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>');
                $('.modal').on('show.bs.modal', function (e) {
                    settings.onStart();
                });
                $('.modal .stock-autocomplete').autocomplete({
                    appendTo: ".modal .stock-container",
                    source: "/ajax/product",
                    minLength: 3,
                    search: function (event, ui) {
                        $('.spinner-' + mainClass).show();
                    },
                    response: function (event, ui) {
                        $('.spinner-' + mainClass).hide();
                    },
                    focus: function (event, ui) {
                        event.preventDefault();
                        //$('#'+modalId+' .stock-autocomplete').val(ui.item.label);
                    },
                    select: function (event, ui) {
                        event.preventDefault();
                        $('#' + modalId + ' .stock-autocomplete').val(ui.item.label);
                        settings.data = {
                            product: ui.item.value,
                            productName: ui.item.label,
                            form1: "",
                            form2: "",
                            qty: 0,
                        };
                        $('#' + settings.output).val(ui.item.value);
                        $('#' + settings.input).val(ui.item.label);
                        settings.stockData.product = settings.data.product;
                        $('#' + modalId + ' .table tbody').empty();
                        $('#' + modalId + ' .table tbody').append('<tr><td collspan=3><i class="fas fa-spinner fa-spin"></i> Идет поиск остатков...</td></tr>');
                        $.ajax({
                            url: '/ajax/stock',
                            type: 'GET',
                            dataType: 'json',
                            data: settings.stockData,
                        })
                            .done(function (data) {
                                $('#' + modalId + ' .table tbody').empty();
                                if (data.length == 0) {
                                    $('#' + modalId + ' .table tbody').append('<tr><td collspan=3>Нет остатков</td></tr>');
                                } else {
                                    data.forEach(function (item, index) {
                                        $('#' + modalId + ' .table tbody').append('<tr><td>' + item.form2 + '</td><td>' + item.qty + '</td><td><button data-dismiss="modal" data-form1="' + item.form1 + '" data-form2="' + item.form2 + '" data-qty="' + item.qty + '">Выбрать</button></td></tr>');
                                    });
                                }
                                console.log("success");
                            })
                            .fail(function () {
                                $('#' + modalId + ' .table tbody').empty();
                                console.log("error");
                            })
                            .always(function () {
                            });

                    }
                });
            }

            $(this).autocomplete({
                appendTo: ".stock-container-" + name,
                source: "/ajax/product",
                minLength: 3,
                select: function (event, ui) {
                    event.preventDefault();
                    $('#' + settings.output).val(ui.item.value);
                    $('#' + settings.input).val(ui.item.label);
                },
                focus: function (event, ui) {
                    event.preventDefault();
                    //$('#'+settings.input).val(ui.item.label);
                },
                search: function (event, ui) {
                    $('.spinner-' + name).show();
                },
                response: function (event, ui) {
                    $('.spinner-' + name).hide();
                }
            });

        });
    };
}(jQuery));
